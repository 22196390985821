<template>
  <v-list class="py-0">
    <v-list-item to="/profile/dashboard">
      <v-list-item-title>{{
        $t("navbar.navigation.dashboard")
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item to="/profile/profile-update">
      <v-list-item-title>{{
        $t("navbar.navigation.profile")
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item to="/profile/orders">
      <v-list-item-title>{{
        $t("navbar.navigation.orders")
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item to="/profile/lists">
      <v-list-item-title>{{ $t("navbar.navigation.lists") }}</v-list-item-title>
    </v-list-item>
    <!-- <v-list-item to="/profile/gifts">
      <v-list-item-title>{{ $t("navbar.navigation.gifts") }}</v-list-item-title>
    </v-list-item> -->
    <v-list-item
      :to="{ name: 'ProfileGift', params: { showMenuNavigator: true } }"
    >
      <v-list-item-title>{{ $t("navbar.navigation.gifts") }}</v-list-item-title>
    </v-list-item>
    <v-list-item to="/profile/addresses">
      <v-list-item-title>{{
        $t("navbar.navigation.addresses")
      }}</v-list-item-title>
    </v-list-item>
    <!-- <v-list-item to="/invite-friend">
      <v-list-item-title>{{
        $t("navbar.navigation.inviteFriend")
      }}</v-list-item-title>
    </v-list-item> -->
    <!-- <v-list-item
      :to="{ name: 'MySubscriptions', params: { showMenuNavigator: true } }"
    >
      <v-list-item-title>{{
        $t("navbar.navigation.subscriptions")
      }}</v-list-item-title>
    </v-list-item> -->
    <v-list-item @click.stop="doLogout()">
      <v-list-item-title>{{
        $t("navbar.navigation.logout")
      }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MenuNavigator",
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      if (Vue.$cookies.isKey("sfliveagentid")) {
        Vue.$cookies.remove("sfliveagentid");
      }
      if (this.$router.name !== "Home") {
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      isMasterAccount: "cart/isMasterAccount"
    })
  }
};
</script>
